export interface TranslationGroup {
  [ key: string ]: Translation;
}

export type Translation = {
  [key in Locale]: string;
}

export enum Locale {
  UK = 'uk',
  RU = 'ru',
}
