// import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  name: 'demo',
  production: true,
  apiBase: 'https://demo-api-yesport.luxnet.io',
  reactionsApiBase: 'https://demo-reaction.luxnet.io',
  counterBase: 'https://demo-yesport.luxnet.io/counter',
  resourceBase: 'https://demo-yesport.luxnet.io',
  host: 'demo-yesport.luxnet.io',
  protocol: 'https://',
  adminDomain: 'https://demo-admin-yesport.luxnet.io/admin',
  // logConf :  {
  //   level: NgxLoggerLevel.WARN,
  //   disableConsoleLogging: false
  // },
};
