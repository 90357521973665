import {Locale} from '../translations/models.translations';

export class Constants {
  public static colors = {
    'divider-color': '#C9AA4C',
    'primary': '#6900FF',
    'secondary': '#A464FF',
    'footer-color': '#F9D976',
    'top-background-yellow-color': '#F9D976',
    'placeholder-background': '#F9D976',
  } as const;

  public static Breakpoints = {
    xs: 360,
    sm: 576,
    md: 768,
    lg: 900,
    xl: 1366,
    xxl: 1680,
  } as const;

  public static socialLinks = {

  };

  public static socialShareLinks = {
    FACEBOOK: 'https://www.facebook.com/sharer/sharer.php',
    TWITTER: 'https://twitter.com/intent/tweet',
    TELEGRAM: 'https://t.me/share/url',
    VIBER: 'viber://forward',
  };

  public static staticRoutes = [
    {
      route: 'general-info',
      key: 'SP_GENERAL_INFO',
    },
    {
      route: 'editorial-principles',
      key: 'SP_EDITORIAL_PRINCIPLES',
    },
    {
      route: 'rules',
      key: 'SP_RULES',
    },
    {
      route: 'policy',
      key: 'SP_POLICY',
    },
  ];


  public static DEFAULT_LOCALE = Locale.UK;
  public static LOCALE_RU = Locale.RU;

  public static LANGUAGE_SHORT_NAME_RU = 'ru';
  public static LANGUAGE_SHORT_NAME_UK = 'uk';

  public static  PROD_DOMAIN = 'https://yesport.com.ua';
  public static  DEMO_DOMAIN = 'https://demo-yesport.luxnet.io/';

  public static ID_FROM_URL_MATCHER = '^[0-9]+';
  public static NEWS_TRANSLITERATION_MATCHER = '/news/(([0-9]+)-[A-Za-z0-9_=-]+)';

  public static X_KNOWN_BOT_HEADER = 'x-known-bot';
  public static CACHE_FORCE_EVICT_HEADER = 'x-cache-smart-bypass';
  //TODO: delete after prod start
  public static CACHE_FORCE_EVICT_HEADER_NGINX = 'x-evict-nginx-cache';
  public static CACHE_FORCE_EVICT = 'cache-force-evict';
  public static LOCATION_HEADER = 'Location';

  public static ALL_NEWS_LINK = 'all-news';
  public static ALL_NEWS_LINK_RU = 'ru/all-news';
  public static SITE_NAME = 'YeSport';

  public static footerStaticLinks = {
    uk: [
      {
        name: 'Про нас',
        link: 'uk/general-info',
      },
      {
        name: 'Контакти',
        link: 'uk/contacts',
      },
      {
        name: 'Правила користування',
        link: 'uk/rules',
      },
      {
        name: 'Редакція',
        link: 'uk/authors',
      },
      {
        name: 'Редакційна політика',
        link: 'uk/editorial-principles',
      },
      {
        name: 'Політика конфіденційності',
        link: 'uk/policy',
      },
      {
        name: 'Архів новин',
        link: 'uk/html-sitemap',
      }
    ],
    ru: [
      {
        name: 'О нас',
        link: 'ru/general-info',
      },
      {
        name: 'Контакты',
        link: 'ru/contacts',
      },
      {
        name: 'Правила пользования',
        link: 'ru/rules',
      },
      {
        name: 'Редакция',
        link: 'ru/authors',
      },
      {
        name: 'Редакционная политика',
        link: 'ru/editorial-principles',
      },
      {
        name: 'Политика конфиденциальности',
        link: 'ru/policy',
      },
      {
        name: 'Архив новостей',
        link: 'ru/html-sitemap',
      }
    ],
  }
}


type ColorKeys = keyof typeof Constants.colors;
export type LayoutColors = typeof Constants.colors[ColorKeys];
